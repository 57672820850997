'use strict';

const datalayer = require('../components/datalayer');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    $('.email-signup-message').remove();
    const status = data.success ? 'alert-success' : 'alert-danger';
    const feedbackElement = $('.feedback-element');

    const msgTemplate = () => {
        return `<div class="toast-message1 email-signup-message">
            <div class="email-signup-alert toast-alert alert ${status} px-0">
                <div class="container">${data.msg}</div>
            </div>
        </div>`;
    };

    if (feedbackElement.length) {
        feedbackElement.append(`${msgTemplate()}`);
    } else {
        $('.js-email-subscription-form').after(`${msgTemplate()}`);
    }

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttribute('disabled');
    }, 3000);
}

/**
 * Subscribes to a URL and displays the response message.
 *
 * @param {string} url - The URL to subscribe to.
 * @param {Object} params - The parameters to send in the request.
 * @param {HTMLElement} submitButton - The submit button element.
 * @return {void} This function does not return anything.
 */
function subscribe(url, params, submitButton) {
    fetch(url, {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams(params)
    }).then(res => {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(res);
    }).then(data => {
        displayMessage(data, submitButton);
        datalayer?.newsletterSubscriptionTracking('subscribe', 'footer');
    }).catch(res => {
        displayMessage({ msg: res.statusText }, submitButton);
    });
}

module.exports = function () {
    const newsletterSubscriptionForm = document.querySelector('.js-email-subscription-form');
    if (newsletterSubscriptionForm) {
        document.querySelector('.js-email-subscription-form').addEventListener('submit', function (e) {
            e.preventDefault();
            const subscriptionForm = this;
            const emailInput = subscriptionForm.querySelector('.js-email-signup');
            const emailRegex = new RegExp(emailInput.dataset.pattern);
            const url = subscriptionForm.querySelector('.subscribe-email').dataset.href;
            const submitButton = subscriptionForm.querySelector('.subscribe-email');
            const emailId = emailInput.value;
            const isEmailValid = emailRegex.test(String(emailId).toLowerCase());

            this.setAttribute('disabled', true);
            if (isEmailValid) {
                let renderCaptchaElement = subscriptionForm.querySelector('.g-recaptcha');
                let params;
                if (renderCaptchaElement) {
                    // eslint-disable-next-line no-undef
                    grecaptcha.ready(function () {
                        const siteKey = renderCaptchaElement.dataset.sitekey;
                        // eslint-disable-next-line no-undef
                        grecaptcha.execute(siteKey, { action: 'newsletter' }).then(function (token) {
                            subscriptionForm.querySelector('input[name="responseToken"]').value = token;
                            const emailInputField = subscriptionForm.querySelector('.js-email-signup');
                            const emailAddress = emailInputField.value;
                            params = new FormData();
                            params.append('emailId', emailAddress);
                            params.set('responseToken', token);
                            $(subscriptionForm).spinner().start();
                            subscribe(url, params, submitButton);
                        });
                    });
                } else {
                    $.spinner().start();
                    params = new FormData();
                    params.append('emailId', emailId);
                    subscribe(url, params, submitButton);
                }
            } else {
                const errorMessage = submitButton.dataset.errorMsg;
                displayMessage({ msg: errorMessage }, this);
            }
        });
    }
};

'use strict';

var processInclude = require('../util');
module.exports = function () {
    $(document).on('remoteHeaderRendered', function () {
        processInclude(require('../../js/components/menu'));
        processInclude(require('../../js/components/drillDownMenu'));
        processInclude(require('../../js/components/footer'));
        processInclude(require('../../js/components/miniCart'));
        processInclude(require('../../js/components/search'));
        processInclude(require('../../js/components/countrySelector'));
        processInclude(require('../../js/product/wishlist'));
    });
};

'use strict';

function updateTextCartModal(data, resources) {
    const modal = document.getElementById('cartTextModal');
    const modalBody = modal.querySelector('.modal-body');
    modalBody.innerHTML = '';

    data.items.forEach(lineItem => {
        const configuratorProductsHtml = lineItem.configuratorProducts && lineItem.configuratorProducts.length > 0
            ? `<ul class="configurator-details x-small mt-2">
                ${lineItem.configuratorProducts.map(configuratorProduct => `<li>${configuratorProduct}</li>`).join('')}
               </ul>`
            : '';

        const lineItemHtml = `
            <div class="mb-1">
                <strong>${lineItem.quantity}x</strong>
                ${lineItem.productName} - <strong>${lineItem.unitPrice}</strong>
            </div>
            <div><strong class="text-uppercase js-line-item-sku">${resources.shareSku}</strong>: ${lineItem.id}</div>
            ${configuratorProductsHtml}
            <hr>
        `;
        modalBody.insertAdjacentHTML('beforeend', lineItemHtml);
    });
}

/**
 * Generates the text cart modal based on provided data and shows it.
 * @param {Object} data - The data object containing all necessary information to populate the modal.
 * @returns {Object} A status object indicating whether the operation was successful, an error message if applicable, and a message flag.
 */
function generateTextCartModal(data) {
    try {
        // Update the text cart popup modal with the appropriate data
        updateTextCartModal(data?.basketModel, data?.resources);
        $('#cartTextModal').modal('show');
        return {
            success: true,
            error: false,
            showMsg: false
        };
    } catch (error) {
        return {
            success: false,
            error: true,
            msg: data?.resources.shareError,
            showMsg: false
        };
    }
}

module.exports = generateTextCartModal;

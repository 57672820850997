'use strict';

module.exports = function (selector, keyFunctions, preFunction) {
    const element = document.querySelector(selector);
    if (element) {
        element.addEventListener('keydown', event => {
            const key = event.which;
            const supportedKeyCodes = [37, 38, 39, 40, 27];

            if (supportedKeyCodes.includes(key)) {
                event.preventDefault();
            }

            const returnedScope = preFunction.call(element);

            if (keyFunctions[key]) {
                keyFunctions[key].call(element, returnedScope);
            }
        });
    }
};

'use strict';

function updateReviewCount(mutation) {
    try {
        const anchorTag = mutation.target.querySelector('a');
        if (anchorTag) {
            const anchorText = anchorTag.textContent;
            const numberMatch = anchorText.match(/\d+/);
            if (numberMatch) {
                anchorTag.textContent = `(${numberMatch[0]})`;
            }
        }
    } catch (error) {
        console.error('Error updating review count:', error);
    }
}

function refreshReviewCounts() {
    const targetNodes = document.querySelectorAll('.product-tile .yotpo');
    const config = {
        childList: true,
        subtree: true
    };

    if (targetNodes.length > 0) {
        const observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach(mutation => {
                if (mutation.type === 'childList' || mutation.type === 'subtree') {
                    updateReviewCount(mutation);
                }
            });
        });

        targetNodes.forEach(targetNode => observer.observe(targetNode, config));
    }
}

function refreshWidgets() {
    if (window.yotpo?.initialized && !document.querySelector('.yotpo-display-wrapper')) {
        try {
            // eslint-disable-next-line no-undef
            let api = new Yotpo.API(yotpo);
            api.refreshWidgets();
            refreshReviewCounts();
        } catch (error) {
            console.error('Error refreshing Yotpo widgets:', error);
        }
    }
}

function init() {
    refreshReviewCounts();
}

module.exports = {
    init,
    refreshWidgets,
    refreshReviewCounts
};

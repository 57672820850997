/* global zE */
const breakpoints = require('../breakpoints');

class ZendeskChat {
    constructor(zendeskElement) {
        this.zendeskElement = zendeskElement;
        this.lastWidth = window.innerWidth;
    }

    init() {
        this.bindZendeskChatEvent();
        this.updateZendeskWidgetForDevice();
        window.addEventListener('resize', () => {
            let currentWidth = window.innerWidth;

            if (Math.abs(this.lastWidth - currentWidth) > 50) {
                this.lastWidth = currentWidth;
                this.updateZendeskWidgetForDevice();
            }
        });
        window.addEventListener('orientationchange', () => this.updateZendeskWidgetForDevice());
    }

    /* eslint-disable class-methods-use-this */
    openZendeskWidget(event) {
        event.preventDefault();
        zE('messenger', 'open');
    }

    /* eslint-disable class-methods-use-this */
    hideZendeskWidget() {
        zE('messenger', 'close');
        zE('messenger', 'hide');
    }

    /* eslint-disable class-methods-use-this */
    showZendeskButton() {
        zE('messenger', 'show');
    }

    setMobileZendeskEventListeners() {
        zE('messenger:on', 'close', () => this.hideZendeskWidget());
        zE('messenger:on', 'open', () => this.showZendeskButton());
    }

    setDesktopZendeskEventListeners() {
        zE('messenger:on', 'close', () => this.showZendeskButton());
    }

    updateZendeskWidgetForDevice() {
        if (breakpoints.down('md')) {
            this.hideZendeskWidget();
            this.setMobileZendeskEventListeners();
        } else {
            this.showZendeskButton();
            this.setDesktopZendeskEventListeners();
        }
    }

    bindZendeskChatEvent() {
        this.zendeskElement.addEventListener('click', (event) => this.openZendeskWidget(event));
        this.zendeskElement.addEventListener('keypress', (event) => {
            if (event.key === 'Enter' || event.key === ' ') {
                this.openZendeskWidget(event);
            }
        });
    }
}

module.exports = function () {
    const zendeskElementCollection = document.querySelectorAll('.js-zendesk-button');

    zendeskElementCollection.forEach(zendeskElement => {
        if (typeof zE === 'function' && !zendeskElement.zendeskChatInitialized) {
            zendeskElement.zendeskChatInitialized = true;
            const zendeskChat = new ZendeskChat(zendeskElement);
            zendeskChat.init();
        }
    });
};

'use strict';

var keyboardAccessibility = require('./keyboardAccessibility');

var clearSelection = function (element) {
    $(element).closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $(element).closest('.dropdown.show').children('.nav-link').attr('aria-expanded', 'false');
    $(element).closest('.dropdown.show').children('.dropdown-menu').attr('aria-hidden', 'true');
    $(element).closest('.dropdown.show').removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $(element).closest('li').detach();
};

module.exports = function () {
    var isDesktop = function (element) {
        return $(element).parents('.menu-toggleable-left').css('position') !== 'fixed';
    };
    var isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0;

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    var $userLoggedInButton = $('.js-header .user-logged-in');
    var userLoggedInButtonTouched = false;
    var $userLoggedInDropdownMenu = $('.js-header .user-logged-in .popover');

    if (isTouchDevice) {
        $userLoggedInButton.on('touchstart', function () {
            $userLoggedInDropdownMenu.addClass('show').attr('aria-expanded', 'true');
            userLoggedInButtonTouched = true;
        });

        $(document).on('touchstart', function () {
            if (!userLoggedInButtonTouched) {
                $userLoggedInDropdownMenu.removeClass('show').attr('aria-expanded', 'false');
            }
            userLoggedInButtonTouched = false;
        });

        $(document).on('click', '#myaccount', function (event) {
            event.preventDefault();
        });
    } else {
        $userLoggedInButton.on('mouseenter focusin', function () {
            $userLoggedInDropdownMenu?.addClass('show').attr('aria-expanded', 'true');
        });
        $userLoggedInButton.on('mouseleave', function () {
            $userLoggedInDropdownMenu?.removeClass('show').attr('aria-expanded', 'false');
        });
    }

    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop(this)) {
                $('.modal-background').show();
                // copy parent element into current UL
                var li = $('<li class="dropdown-item top-category" role="button"></li>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                var closeMenu = $('<li class="nav-menu"></li>');
                closeMenu.append($('.close-menu').first().clone());
                $(this).parent().children('.dropdown-menu')
                    .prepend(li)
                    .prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                // copy navigation menu into view
                $(this).parent().addClass('show');
                $(this).attr('aria-expanded', 'true');
                $(link).focus();
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                e.preventDefault();
            }
        })
        .on('mouseenter', function () {
            if (isDesktop(this)) {
                var eventElement = this;
                $('.navbar-nav > li').each(function () {
                    if (!$.contains(this, eventElement)) {
                        $(this).find('.show').each(function () {
                            clearSelection(this);
                        });
                        if ($(this).hasClass('show')) {
                            $(this).removeClass('show');
                            $(this).children('ul.dropdown-menu').removeClass('show');
                            $(this).children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // need to close all the dropdowns that are not direct parent of current dropdown
                $(this).parent().addClass('show');
                $(this).siblings('.dropdown-menu').addClass('show');
                $(this).attr('aria-expanded', 'true');
            }
        })
        .parent()
        .on('mouseleave', function () {
            if (isDesktop(this)) {
                $(this).removeClass('show');
                $(this).children('.dropdown-menu').removeClass('show');
                $(this).children('.nav-link').attr('aria-expanded', 'false');
            }
        });

    keyboardAccessibility(
        '.js-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.js-header .user .popover').removeClass('show');
                $('.js-header  .user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.js-header .user .popover').removeClass('show');
                $('.js-header  .user').attr('aria-expanded', 'false');
            }
        },
        function () {
            var $popover = $('.js-header  .user .popover li.nav-item');
            return $popover;
        }
    );
};

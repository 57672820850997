'use strict';

window.jQuery = require('jquery');
window.$ = require('jquery');
var processInclude = require('./util');

$(document).ready(function () {
    processInclude(require('./components/scrollPerformance'));
    processInclude(require('./components/menu'));
    processInclude(require('./components/drillDownMenu'));
    processInclude(require('./components/cookie'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/toolTip'));
    processInclude(require('./components/initPopOver'));
    processInclude(require('./components/swiperSlider'));
    processInclude(require('./thirdParty/zendeskinit'));
    processInclude(require('./product/wishlist'));
    processInclude(require('./components/videoElement'));
    processInclude(require('./thirdParty/bootstrapToggleStateManager'));
    processInclude(require('./components/countdown'));
    processInclude(require('./components/scrollTo'));
    processInclude(require('./components/initStickyHeader'));
    processInclude(require('./components/initYotpoReview').init);
    processInclude(require('./components/initRemoteHeaderAndFooter'));
    processInclude(require('./components/shareBtn'));
});

require('./thirdParty/bootstrap');
require('./components/spinner');

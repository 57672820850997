class ToggleStateManager {
    constructor(element) {
        this.element = element;
        this.groupIdentifier = 'refinementsState';
        this.identifier = this.getIdentifier();
    }

    init() {
        this.applyStoredState();
        this.element.addEventListener('click', () => this.toggleState());
    }

    getIdentifier() {
        return this.element.getAttribute('data-bs-target')?.replace('#', '');
    }

    getStoredStates() {
        const stored = localStorage.getItem(this.groupIdentifier);
        return stored ? JSON.parse(stored) : {};
    }

    applyStoredState() {
        const states = this.getStoredStates();
        const state = states[this.identifier];
        const collapseContent = document.getElementById(this.identifier);
        const collapseTrigger = document.querySelector('[data-bs-target="#' + this.identifier + '"]');

        if (state !== undefined) {
            if (state) {
                collapseContent.classList.add('show');
                collapseTrigger.classList.remove('collapsed');
                collapseTrigger.setAttribute('aria-expanded', 'true');
            } else {
                collapseContent.classList.remove('show');
                collapseTrigger.classList.add('collapsed');
                collapseTrigger.setAttribute('aria-expanded', 'false');
            }
        }
    }

    toggleState() {
        const newState = this.element.getAttribute('aria-expanded') === 'true';

        const states = this.getStoredStates();
        states[this.identifier] = newState;
        localStorage.setItem(this.groupIdentifier, JSON.stringify(states));
    }
}

module.exports = function () {
    const toggleStateManagerInit = () => {
        const togglerElements = document.querySelectorAll('.js-bs-toggle-collapse');

        togglerElements.forEach(togglerElement => {
            const toggleStateManager = new ToggleStateManager(togglerElement);
            toggleStateManager.init();
        });
    };

    toggleStateManagerInit();
    document.addEventListener('search:filtered', () => toggleStateManagerInit());
};

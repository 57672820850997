'use strict';

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} tile - tile that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, tile) {
    $.spinner().stop();
    let status;
    const wishlistQuantityIndicatorEle = document.querySelector('.js-wishlist-quantity-indicator');

    if (data.success && data.event === 'add') {
        status = 'alert-success';
        window.wishlist.items.push({ pid: data.pid });
        tile.closest('.wishlist-tile').addClass('added');
    } else if (data.success && data.event === 'remove') {
        status = 'alert-success';
        tile.closest('.wishlist-tile').removeClass('added');
        window.wishlist.items = window.wishlist.items.filter(item => item.pid !== data.pid);
    } else {
        status = 'alert-danger';
    }

    if (wishlistQuantityIndicatorEle && window?.wishlist?.items?.length > 0) {
        wishlistQuantityIndicatorEle.innerText = `${window.wishlist.items.length}`;
        wishlistQuantityIndicatorEle?.classList.remove('d-none');
    } else {
        wishlistQuantityIndicatorEle?.classList.add('d-none');
    }

    const feedbackElement = $('.feedback-element');
    if (feedbackElement) {
        feedbackElement.append(`<div class="toast-message add-to-wishlist-messages">
                                    <div class="toast-alert alert ${status} add-to-wishlist-alert px-0" role="alert">
                                        <div class="container">${data.msg}</div>
                                    </div>
                                </div>`);
    }

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 3000);

    var sameProducts = document.querySelectorAll('[data-pid="' + data.pid + '"]');
    if (sameProducts.length > 0) {
        sameProducts.forEach(function (product) {
            if (product.querySelector('.wishlist-tile')) {
                if (data.event === 'add') {
                    product.querySelector('.wishlist-tile').classList.add('added');
                } else {
                    product.querySelector('.wishlist-tile').classList.remove('added');
                }
            }
        });
    }
}

module.exports = {
    init: function () {
        const wishlist = window.wishlist;
        const wishlistItems = wishlist ? wishlist.items : [];
        const wishlistQuantityIndicatorEle = document.querySelector('.js-wishlist-quantity-indicator');
        if (wishlistItems?.length > 0 && wishlistQuantityIndicatorEle) {
            wishlistQuantityIndicatorEle.innerText = wishlistItems.length;
            wishlistQuantityIndicatorEle?.classList?.remove('d-none');
        } else {
            wishlistQuantityIndicatorEle?.classList?.add('d-none');
        }

        const productsEle = document.querySelectorAll('.product');
        productsEle.forEach(function (product) {
            var pid = product.dataset.pid;
            if (wishlistItems.length > 0) {
                wishlistItems.forEach(function (item) {
                    if (item.pid === pid) {
                        product.querySelector('.wishlist-tile').classList.add('added');
                    }
                });
            }
        });
    },
    addToWishlistHeart: function () {
        $('body').on('click', '.wishlist-tile', function (e) {
            e.preventDefault();
            e.stopImmediatePropagation();
            const icon = $(this).find($('svg'));
            const addWishtlistUrl = $(this).attr('data-add-url');
            const removeWishtlistUrl = $(this).attr('data-remove-url');
            const pid = $(this).closest('.product').attr('data-pid');
            const tile = $(this).closest('.wishlist-tile');

            let optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
            let optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
            optionId = optionId || null;
            optionVal = optionVal || null;
            let optionRadioId = $(this).closest('.product-detail').find('.product-option-radio').attr('data-option-id');
            let optionRadioVal = $(this).closest('.product-detail').find('.js-options-radio:checked').attr('data-value-id');
            optionRadioId = optionRadioId || null;
            optionRadioVal = optionRadioVal || null;

            optionId = optionId || optionRadioId;
            optionVal = optionVal || optionRadioVal;

            if (!addWishtlistUrl || !pid) {
                return;
            }

            if (!tile.hasClass('added')) {
                $.spinner().start();
                $.ajax({
                    url: addWishtlistUrl,
                    type: 'post',
                    dataType: 'json',
                    data: {
                        pid,
                        optionId,
                        optionVal
                    },
                    success: function (data) {
                        if (typeof window.checkResponseForEventDetails === 'function') {
                            window.checkResponseForEventDetails(data);
                        }
                        data.event = 'add';
                        displayMessageAndChangeIcon(data, tile);
                    },
                    error: function (err) {
                        displayMessageAndChangeIcon(err, icon);
                    }
                });
            } else {
                if (!removeWishtlistUrl || !pid) {
                    return;
                }

                $.ajax({
                    url: `${removeWishtlistUrl}?${$.param({ pid: pid })}`,
                    type: 'get',
                    success: function (data) {
                        if (typeof window.checkResponseForEventDetails === 'function') {
                            window.checkResponseForEventDetails(data);
                        }
                        data.event = 'remove';
                        data.pid = pid;
                        displayMessageAndChangeIcon(data, tile);
                    },
                    error: function (err) {
                        console.error(err);
                    }
                });
            }
        });
    }
};

'use strict';

const breakpoints = require('../breakpoints');
var cartHelper = require('../cart/cartHelper');
var updateMiniCart = true;

module.exports = function () {
    $('body').on('change', '.quantity-form > .quantity', function () {
        var preSelectQty = $(this).attr('data-pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');

        var urlParams = {
            pid: productID,
            quantity: quantity,
            originalQuantity: preSelectQty,
            uuid: uuid
        };
        url = cartHelper.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    cartHelper.createErrorNotification(data.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                } else {
                    $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                    $('.quantity[data-uuid="' + uuid + '"]').attr('data-pre-select-qty', quantity);
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    cartHelper.updateCartTotals(data);
                    cartHelper.updateApproachingDiscounts(data.approachingDiscounts);
                    cartHelper.updateAvailability(data, uuid);
                    cartHelper.validateBasket(data);
                    $(this).data('pre-select-qty', quantity);

                    $('body').trigger('cart:update', data);

                    $.spinner().stop();
                    if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                        window.location.reload();
                    }

                    $(this).parents('.card-body').find('.remove-product').data('qty', quantity);

                    if (typeof window.checkResponseForEventDetails === 'function') {
                        window.checkResponseForEventDetails(data);
                    }
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelper.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var qty = $(this).data('qty');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        cartHelper.confirmDelete(actionUrl, productID, productName, uuid, qty);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        cartHelper.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var qty = $(this).data('qty');
        var urlParams = {
            pid: productID,
            uuid: uuid,
            qty: qty
        };

        url = cartHelper.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (typeof window.checkResponseForEventDetails === 'function') {
                    window.checkResponseForEventDetails(data);
                }

                const cartQuantityIndicatorEle = document.querySelector('.minicart-quantity');

                if (data.basket.items.length === 0) {
                    $('.cart').empty().append('<div class="card"> '
                        + '<div class="card-body text-center py-9"> '
                        + '<h1 class="mb-0">' + data.basket.resources.emptyCartMsg + '</h1> '
                        + '<a class="btn btn-lg btn-primary mt-8" href="' + data.basket.resources.buttonUrl + '">' + data.basket.resources.buttonText + '</a> '
                        + '</div> '
                        + '</div>');
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');
                    cartQuantityIndicatorEle?.classList?.add('d-none');
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).remove();
                        }
                    }
                    $('.uuid-' + uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    $('.js-shipping-methods').empty().append(data.basket.renderedShippingMethods);
                    cartHelper.updateCartTotals(data.basket);
                    cartHelper.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    cartHelper.validateBasket(data.basket);
                    cartQuantityIndicatorEle?.classList?.remove('d-none');
                }

                cartHelper.updateExpressPaymentMethods(data.basket);
                cartHelper.updateCountdownVisibility(data);

                $('body').trigger('cart:update', data);

                if (!data?.basket?.isPreOrder) {
                    $('.js-preorder-warning-message')?.remove();
                }

                $.spinner().stop();
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    cartHelper.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.minicart').on('count:update', function (event, count) {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').removeClass('d-none');
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });

            updateMiniCart = true;
        }
    });

    $('.minicart').on('mouseenter focusin touchstart', function () {
        if (breakpoints.down('lg')) {
            return;
        }
        var url = $('.minicart').data('action-url');
        var count = parseInt($('.minicart .minicart-quantity').text(), 10);

        if (count !== 0 && $('.minicart .popover.show').length === 0) {
            if (!updateMiniCart) {
                $('.minicart .popover').addClass('show');
                return;
            }

            $('.minicart .popover').addClass('show');
            $.get(url, function (data) {
                $('.minicart .popover').empty();
                $('.minicart .popover').append(data);
                updateMiniCart = false;
                $.spinner().stop();
            });
        }
    });
    $('body').on('touchstart click', function (e) {
        if ($('.minicart').has(e.target).length <= 0) {
            $('.minicart .popover').removeClass('show');
        }
    });
    $('.minicart').on('mouseleave focusout', function (event) {
        if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
            || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
            || $('body').hasClass('modal-open')) {
            event.stopPropagation();
            return;
        }
        $('.minicart .popover').removeClass('show');
    });
    $('body').on('change', '.minicart .quantity', function () {
        if ($(this).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            window.location.reload();
        }
    });
    $('body').on('product:afterAddToCart', function () {
        updateMiniCart = true;
    });
    $('body').on('cart:update', function () {
        updateMiniCart = true;
    });
};

'use strict';

class VideoConsentHandler {
    constructor(component) {
        this.component = component;
    }

    init() {
        this.videoElement = this.component.querySelector('.js-video-element-iframe');
        this.renewCookieBtn = this.component.querySelector('.js-review-cookie');

        if (!this.videoElement || !this.renewCookieBtn) {
            console.error('VideoConsentHandler: Necessary elements not found within the component.');
            return;
        }

        this.cookiebotEnabled = this.getCookieBotEnabled();
        this.handleConsentClick = this.handleConsentClick.bind(this);
        this.renewCookieBtn.addEventListener('click', this.handleConsentClick);
    }

    getCookieBotEnabled() {
        return this.videoElement.dataset.cookiebotConfigured === 'true' && window.Cookiebot;
    }

    handleConsentClick() {
        if (this.cookiebotEnabled) {
            window.Cookiebot.renew();
        } else {
            console.warn('Cookiebot is not configured or renewCookieBot is not a function.');
        }
    }
}

module.exports = function () {
    const videoComponents = document.querySelectorAll('.js-video-element-container');

    if (videoComponents) {
        videoComponents.forEach(component => {
            const videoConsentHandler = new VideoConsentHandler(component);
            videoConsentHandler.init();
        });
    }
};

'use strict';

const materialIcon = require('../materialIcon');

/**
 * Displays a feedback message within a specified container.
 * The message is automatically removed after a specified duration.
 * @param {Object} options - Configuration options for the feedback message.
 * @param {string} options.containerSelector - The selector for the container where the message will be appended.
 * @param {string} options.message - The text content of the feedback message.
 * @param {string} options.iconHTML - HTML string for the icon to be displayed in the message.
 * @param {string} options.alertType - Class to define the type of alert (e.g., 'alert-success', 'alert-danger').
 * @param {number} [options.timeout=3000] - Duration in milliseconds before the message is automatically removed.
 */
function showToastMessage(options) {
    // Default options with customization possibilities
    const defaults = {
        containerSelector: '.feedback-element',
        alertType: 'alert-danger',
        message: 'error',
        iconHTML: materialIcon.error,
        timeout: 3000
    };

    // Merge defaults with user-provided options
    const settings = { ...defaults, ...options };

    // Select the container where the message will be appended
    const feedbackElement = $(settings.containerSelector);

    if (feedbackElement.length) {
        // Append the custom message HTML to the container
        feedbackElement.append(`
            <div class="toast-message copy-link-message">
                <div class="toast-alert copy-link-alert alert ${settings.alertType} px-0" role="alert">
                    <div class="container d-flex align-items-center">
                        <span class="svg-wrapper-inherit me-3 material-icon-wrapper-small lh-1">${settings.iconHTML}</span>
                        ${settings.message}
                    </div>
                </div>
            </div>`);

        setTimeout(() => {
            $('.copy-link-message').remove();
        }, settings.timeout);
    }
}

module.exports = {
    showToastMessage: showToastMessage
};

'use strict';

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 1920,
    up: device => window.innerWidth >= breakpoints[device],
    down: device => window.innerWidth < breakpoints[device]
};

module.exports = breakpoints;

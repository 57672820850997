'use strict';

const keyboardAccessibility = require('./keyboardAccessibility');

module.exports = function () {
    const countrySelectorLinks = document.querySelectorAll('.country-selector a, .mobile-country-selector li');

    countrySelectorLinks.forEach(link => {
        link.addEventListener('click', event => {
            event.preventDefault();
            $.spinner().start();
            const action = document.querySelector('.page').dataset.action;
            const localeCode = link.dataset.locale;
            const localeCurrencyCode = link.dataset.currencycode;
            const queryString = document.querySelector('.page').dataset.querystring;
            const payload = `?code=${localeCode}&queryString=${encodeURIComponent(queryString)}&CurrencyCode=${localeCurrencyCode}&action=${action}`;
            const payloadUrl = event.target.closest('.js-country-selector').dataset.url + payload;

            fetch(payloadUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                if (res.ok) {
                    return res.json();
                }
                return Promise.reject(res);
            }).then(data => {
                if (data && data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                }
            }).catch(() => {
                $.spinner().stop();
            });
        });
    });

    keyboardAccessibility(
        '.header-top-bar .country-selector',
        {
            9: function () { // tab
                const dropdownToggle = this.querySelector('.dropdown-toggle');

                if (dropdownToggle.classList.contains('show')) {
                    const eventClick = new Event('click');

                    dropdownToggle.dispatchEvent(eventClick);
                }
            }
        },
        function () {
            return this.querySelector('.dropdown-country-selector a');
        }
    );

    const countrySelector = document.querySelector('.header-top-bar .country-selector');
    if (countrySelector) {
        document.querySelector('.header-top-bar .country-selector').addEventListener('focusin', function (e) {
            if (e.target === this) {
                const eventClick = new Event('click');
                this.querySelector('.dropdown-toggle').dispatchEvent(eventClick);
            }
        });
    }
};

'use strict';

const { getTranslation } = require('../common/jsTranslations');

function adjustFieldState($field, errorMsg) {
    if (!$field) {
        return;
    }

    if (errorMsg) {
        $field.addClass('is-invalid');
        $field.siblings().filter('.invalid-feedback').text(errorMsg);
    } else {
        $field.removeClass('is-invalid');
        $field.siblings().filter('.invalid-feedback').text('');
    }
}

function getCustomValidationMsg($field) {
    if (!$field) {
        return '';
    }

    const emojiRegEx = /\p{Emoji_Presentation}/gu;
    if (emojiRegEx.test($field.val())) {
        return getTranslation('error.msg.emoji');
    }

    const chineseRegEx = /\p{Script=Han}/u;
    const japaneseRegEx = /[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/;
    const koreanRegEx = /[\uac00-\ud7af]|[\u1100-\u11ff]|[\u3130-\u318f]|[\ua960-\ua97f]|[\ud7b0-\ud7ff]/;
    const hinduRegEx = /[\u0900-\u097F]/;
    if (chineseRegEx.test($field.val())
        || japaneseRegEx.test($field.val())
        || koreanRegEx.test($field.val())
        || hinduRegEx.test($field.val())
        || /\p{sc=Cyrillic}/u.test($field.val())
        || /\p{sc=Greek}/u.test($field.val())
        || /\p{sc=Ethiopic}/u.test($field.val())
        || /\p{sc=Arabic}/u.test($field.val())
        || /\p{sc=Hebrew}/u.test($field.val())
        || /\p{sc=Armenian}/u.test($field.val())
        || /\p{sc=Georgian}/u.test($field.val())
    ) {
        return getTranslation('error.msg.latin.alphabet.only');
    }

    return '';
}

function applyCustomValidations($field) {
    const errorMsg = getCustomValidationMsg($field);

    adjustFieldState($field, errorMsg);

    return errorMsg.length === 0;
}

function checkTextFieldsCustomValidation(form) {
    let isValid = true;
    const inputs = $(form).find('input, textarea');

    for (let i = 0; i < inputs.length; i++) {
        const input = inputs[i];
        if (!applyCustomValidations($(input))) {
            isValid = false;
        }
    }

    return isValid;
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
    var valid = true;
    if (
        ($(this).is('form') && this.checkValidity && !this.checkValidity())
        || !checkTextFieldsCustomValidation(this)
    ) {
        // safari
        valid = false;

        $(this).find('input, select').each(function () {
            if (!this.validity.valid) {
                $(this).trigger('invalid', this.validity);
            }
        });
    }

    if (!valid) {
        $(this).find('.is-invalid:first').get(0).scrollIntoView({
            behavior: 'smooth',
            block: 'center'
        });
        if (event) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }
    }

    return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
    $(form).find('.form-control.is-invalid').removeClass('is-invalid');
}

module.exports = {
    invalid: function () {
        $('form input, form select').on('invalid', function (e) {
            e.preventDefault();
            this.setCustomValidity('');
            if (!this.validity.valid) {
                var validationMessage = this.validationMessage;
                $(this).addClass('is-invalid');
                if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
                    validationMessage = $(this).data('pattern-mismatch');
                }
                if ((this.validity.rangeOverflow || this.validity.rangeUnderflow)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if ((this.validity.tooLong || this.validity.tooShort)
                    && $(this).data('range-error')) {
                    validationMessage = $(this).data('range-error');
                }
                if (this.validity.valueMissing && $(this).data('missing-error')) {
                    validationMessage = $(this).data('missing-error');
                }
                $(this).parents('.form-group, .form-floating').find('.invalid-feedback')
                    .text(validationMessage);
            }
        });
    },
    validateZipCode: function ($field) {
        if (!$field) {
            return;
        }
        const validationMessage = $field.data('pattern-mismatch');
        const pattern = new RegExp($field.attr('pattern'));
        const minlength = parseInt($field.attr('minlength'));
        const maxlength = parseInt($field.attr('maxlength'));

        if ($field.val().length < minlength || $field.val().length > maxlength) {
            $field.addClass('is-invalid');
            $field.siblings().filter('.invalid-feedback').text(validationMessage);
        } else if (!pattern.test($field.val())) {
            $field.addClass('is-invalid');
            $field.siblings().filter('.invalid-feedback').text(validationMessage);
        } else {
            $field.removeClass('is-invalid');
            $field.siblings().filter('.invalid-feedback').text('');
        }
    },
    initZipCodeValidation: function () {
        try {
            const $zipCodeFields = $('[data-validate-zip-country-target]');
            const validateZipCode = this.validateZipCode;

            $zipCodeFields.each(function () {
                const $zipCodeField = $(this);

                const form = $zipCodeField.closest('form');
                if (!form) {
                    return;
                }
                const zipCodeRules = JSON.parse(form.attr('data-zip-validation-rules'));

                if (!Array.isArray(zipCodeRules)) {
                    return;
                }

                const countryFieldTarget = $zipCodeField.attr('data-validate-zip-country-target');
                const $countryField = $(`${countryFieldTarget}`);
                const zipCodeRuleDefault = zipCodeRules.filter(rule => rule.countryCode === $countryField.val())[0]?.postalCode;

                $zipCodeField.attr('pattern', zipCodeRuleDefault);

                $countryField.on('change', function () {
                    const zipCodeRule = zipCodeRules.filter(rule => rule.countryCode === $(this).val())[0]?.postalCode;
                    $zipCodeField.attr('pattern', zipCodeRule);
                    validateZipCode($zipCodeField);
                });

                $zipCodeField.on('input blur', function () {
                    validateZipCode($zipCodeField);
                });
            });
        } catch (err) {
            console.error(err);
        }
    },
    initFieldCustomValidations: function () {
        try {
            $('input, textarea').each(function () {
                const $field = $(this);
                $field.on('input blur', function () {
                    applyCustomValidations($field);
                });
            });
        } catch (err) {
            console.error(err);
        }
    },
    submit: function () {
        $('form').on('submit', function (e) {
            return validateForm.call(this, e);
        });
    },
    validateForm: function ($form) {
        return validateForm.call($form, null);
    },
    buttonClick: function () {
        $('form button[type="submit"], form input[type="submit"]').on('click', function () {
            // clear all errors when trying to submit the form
            clearForm($(this).parents('form'));
        });
    },

    functions: {
        validateForm: function (form, event) {
            validateForm.call($(form), event || null);
        },
        clearForm: clearForm
    }
};

'use strict';

function productTileTracking(productTiles) {
    try {
        if (!window.datalayerListInitialView && typeof window.datalayerListInitialView !== 'object') {
            return;
        }

        const event = Object.assign({}, ...window.datalayerListInitialView);

        if (event?.ecommerce?.items) {
            event.ecommerce.items = [];
        }

        let containerId;

        productTiles.forEach((node) => {
            const view = JSON.parse(node.dataset.trackingProductTile);
            const tileContainer = node.closest('[data-tracking-position]');
            const tilePosition = tileContainer ? parseInt(tileContainer.dataset.trackingPosition) : '';

            if (!containerId) {
                containerId = node.closest('[data-tile-container-id]');
            }

            const tileObj = Object.assign({}, ...view);
            tileObj.index = tilePosition;
            event.ecommerce.items.push(tileObj);
            if (containerId) {
                event.ecommerce.item_list_id = containerId.dataset.tileContainerId || '';
                event.ecommerce.item_list_name = containerId.dataset.tileContainerId
                    ? containerId.dataset.tileContainerId.replaceAll('-', ' ') : '';
            }
            delete node.dataset.trackingProductTile;
        });
        window.dataLayer.push(event);
    } catch (err) {
        console.error(err);
    }
}

function accountRegistrationTracking(location) {
    window?.dataLayer?.push({
        event: 'sign_up',
        location: `${location}`
    });
}

function newsletterSubscriptionTracking(event, location) {
    window?.dataLayer?.push({
        event: `${event}`,
        preference: 'email',
        location: `${location}`
    });
}

module.exports = {
    productTileTracking,
    accountRegistrationTracking,
    newsletterSubscriptionTracking
};

'use strict';

class CountdownManager {
    constructor() {
        this.cache = {
            document: document,
            countdownTimers: document.querySelectorAll('.delivery-countdown')
        };
        this.initialized = false;
    }

    init() {
        this.initializeCache();
        this.initializeCountdown();
        this.initialized = true;
    }

    initializeCache() {
        this.cache = {
            document: document,
            countdownTimers: document.querySelectorAll('.delivery-countdown')
        };
    }

    initializeCountdown() {
        this.cache.countdownTimers.forEach(timer => {
            timer.intervalId = setInterval(() => this.updateCountdown(timer), 1000);
        });
    }

    updateCountdown(timer) {
        const endTime = timer.querySelector('.cutoff-time').getAttribute('data-end-time');
        const timeRemaining = this.getTimeRemaining(endTime);

        if (timeRemaining.total <= 0) {
            clearInterval(timer.intervalId);
            timer.closest('.delivery-countdown').style.display = 'none';
        } else {
            const formattedTime = this.formatTime(timeRemaining);
            const timerElement = timer.querySelector('.cutoff-time');
            timerElement.textContent = formattedTime;
            timerElement.classList.remove('text-blurry');
        }
    }

    /* eslint-disable class-methods-use-this */
    getTimeRemaining(endTime) {
        const now = Date.parse(new Date());
        const timeDifference = endTime - now;

        return {
            total: timeDifference,
            days: Math.floor(timeDifference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((timeDifference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((timeDifference / 1000 / 60) % 60),
            seconds: Math.floor((timeDifference / 1000) % 60)
        };
    }

    /* eslint-disable class-methods-use-this */
    formatTime(timeRemaining) {
        const hours = ('0' + timeRemaining.hours).slice(-2) + ' h ';
        const minutes = ('0' + timeRemaining.minutes).slice(-2) + ' m ';
        const seconds = ('0' + timeRemaining.seconds).slice(-2) + ' s';
        return hours + minutes + seconds;
    }
}

module.exports = function () {
    const countdownManager = new CountdownManager();
    if (!countdownManager.initialized) {
        countdownManager.init();
    }
};

'use strict';

const materialIcon = require('../materialIcon');
const initPopOver = require('../components/initPopOver');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    $('.number-of-items').empty().append(data.resources.numberOfItems);
    $('.shipping-cost').empty().append(data.totals.totalShippingCost);
    $('.tax-total').empty().append(data.totals.totalTax);
    $('.grand-total').empty().append(data.totals.grandTotal);
    $('.sub-total').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });
    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $('.order-discount').removeClass('hide-order-discount');
        $('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $('.order-discount').addClass('hide-order-discount');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $('.shipping-discount').removeClass('hide-shipping-discount');
        $('.shipping-discount-total').empty().append('- '
            + data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $('.shipping-discount').addClass('hide-shipping-discount');
    }

    data.items.forEach(function (item) {
        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $('.item-' + item.UUID).empty();
        }

        let unitPrice = item.renderedPrice;

        if (item.renderedConfiguratorPrice) {
            unitPrice = item.renderedConfiguratorPrice;
            $('.line-item-header > a').attr('href', item.url);
        }

        $('.uuid-' + item.UUID + ' .unit-price').empty().append(unitPrice);
        $('.line-item-price-' + item.UUID + ' .unit-price').empty().append(unitPrice);
        $('.item-total-' + item.UUID).empty().append(item.priceTotal.renderedPrice);
    });

    if (data.totals.klarnaTotal) {
        $('klarna-placement').attr('data-purchase-amount', data.totals.klarnaTotal);
        if (window.Klarna && window.Klarna.OnsiteMessaging) {
            window.Klarna.OnsiteMessaging.refresh();
        }
    }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    var errorHtml = `
        <div class="alert alert-danger alert-dismissible valid-cart-error rounded-0 border-0 px-0 fade show" role="alert">
            <div class="container">
                ${message}
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        </div>
    `;
    $('.cart-error').append(errorHtml);
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
    var html = '';
    $('.approaching-discounts').empty();
    if (approachingDiscounts.length > 0) {
        approachingDiscounts.forEach(function (item) {
            html += '<div class="single-approaching-discount text-center">'
                + item.discountMsg + '</div>';
        });
    }
    $('.approaching-discounts').append(html);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();
        const status = lineItem?.availability?.status[0];
        const availabilityHelpTextButton = (message) => {
            const helpText = lineItem?.availability?.helpText[0];
            return helpText ? `
                <button
                    class="btn-reset text-inherit p-1 lh-1"
                    title="${message}"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover focus"
                    data-bs-placement="auto"
                    data-bs-html="true"
                    data-bs-container="body"
                    data-bs-content="${helpText}">
                    <span class="svg-wrapper-inherit material-icon-wrapper-xx-small">
                        ${materialIcon.info}
                    </span>
                </button>
            ` : '';
        };

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += `<span class="product-availability-message availability-msg product-availability-message-${status} d-inline-flex align-items-center mw-100">
                                    <span class="text-truncate">${message}</span>${availabilityHelpTextButton(message)}
                                </span>`;
                });
            }

            if (lineItem.availability.inStockDate) {
                messages += '<p class="line-item-attributes line-item-instock-date">'
                    + lineItem.availability.inStockDate
                    + '</p>';
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
        initPopOver();
    }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            var errorHtml = `
                <div class="alert alert-danger alert-dismissible valid-cart-error rounded-0 border-0 px-0 fade show" role="alert">
                    <div class="container">
                        ${data.valid.message}
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div>
                </div>
            `;
            $('.cart-error').append(errorHtml);
        } else {
            $('.cart').empty().append('<div class="card"> '
                + '<div class="card-body text-center py-9"> '
                + '<h1 class="mb-5">' + data.resources.emptyCartMsg + '</h1> '
                + '</div>'
                + '</div>');
            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {number} qty - qty
 */
function confirmDelete(actionUrl, productID, productName, uuid, qty) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);
    $deleteConfirmBtn.data('qty', qty);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Updates the express payment methods based on the provided data.
 *
 * @param {Object} data - The data containing the express checkout information.
 * @param {boolean} data.expressCheckoutDisabled - Indicates whether express checkout is disabled.
 * @param {string} data.renderedAmazonExpressButton - The rendered amazon express button.
 * @param {string} data.renderedKlarnaExpressButton - The rendered klarna express button.
 * @return {void} This function does not return anything.
 */
function updateExpressPaymentMethods(data) {
    var $expressSeparator = $('.checkout-continue').find('.line-through');

    if (!data.expressCheckoutDisabled) {
        $('.amazon-express-button')?.html(data.renderedAmazonExpressButton);
        $('.kec-cart')?.html(data.renderedKlarnaExpressButton);
        if ($('.kec-cart').length) {
            window.klarnaAsyncCallback?.();
        }
        $('.js-kosm-cart')?.html(data.renderedKOSMCart);
    } else {
        $('.amazon-express-button')?.html('');
        $('.kec-cart')?.html('');
        $('.js-kosm-cart')?.html('');
    }

    if ($expressSeparator.length) {
        $expressSeparator.toggleClass('d-none', data.expressCheckoutDisabled);
    }
}

/**
 * Toggles visibility of elements based on the state of the shopping basket and product conditions.
 * Elements are shown or hidden by switching between Bootstrap classes `d-none` and `d-block`.
 * @param {Object} data - Object containing basket and product properties.
 */
function updateCountdownVisibility(data) {
    const countdownWrapper = $('.js-countdown-wrapper');

    $(countdownWrapper).each(function () {
        const shouldShowElement = data.basket.valid && !data.basket.valid.error && !data.hasConfiguratorProduct && !data.hasPreOrder;

        if (shouldShowElement) {
            $(this).removeClass('d-none').addClass('d-block');
        } else {
            $(this).removeClass('d-block').addClass('d-none');
        }
    });
}

module.exports = {
    appendToUrl: appendToUrl,
    updateCartTotals: updateCartTotals,
    createErrorNotification: createErrorNotification,
    updateApproachingDiscounts: updateApproachingDiscounts,
    updateAvailability: updateAvailability,
    validateBasket: validateBasket,
    confirmDelete: confirmDelete,
    updateExpressPaymentMethods: updateExpressPaymentMethods,
    updateCountdownVisibility: updateCountdownVisibility
};

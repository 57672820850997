'use strict';

const breakpoints = require('../breakpoints');
const DATA_MOBILE_HEIGHT = 'data-mobile-height';

class DrillDownMenu {
    constructor(drillDownMenu) {
        this.drillDownMenu = drillDownMenu;
        this.siteNavToggleBtn = this.drillDownMenu.querySelector('.js-bind-class-mega-menu');
        this.siteNavHeaderSelector = this.drillDownMenu.querySelector('#siteNavHeaderSelector');
        this.mobileWrapper = this.drillDownMenu.querySelector('.ddtmm-menu-mobile-wrapper');
    }

    mapEvents() {
        const siteNavToggle = this.drillDownMenu.querySelector('.ddtmm-site-nav-toggle');
        const siteNav = this.drillDownMenu.querySelector('.ddtmm-site-nav');

        siteNavToggle.addEventListener('click', () => {
            siteNavToggle.classList.toggle('active');
            siteNav.classList.toggle('active');

            // add overlay and disable body scroll only if <1400
            if (breakpoints.down('xxl')) {
                document.body.classList.add('overflow-hidden--max-lg');
            }
        });

        siteNav?.addEventListener('transitionend', () => {
            if (breakpoints.down('xxl')) {
                const siteNavOverlay = this.drillDownMenu.querySelector('.ddtmm-site-nav-overlay');
                if (siteNav.classList.contains('active') && !siteNavOverlay) {
                    const overlay = document.createElement('div');
                    overlay.classList.add('ddtmm-site-nav-overlay', 'js-site-nav-x');
                    siteNav.after(overlay);
                }
            }
        });

        const subMenuTriggers = this.drillDownMenu.querySelectorAll('.ddtmm-sub-menu-trigger');
        subMenuTriggers.forEach(subMenuTrigger => {
            subMenuTrigger.addEventListener('click', event => {
                if (breakpoints.down('xxl')) {
                    event.preventDefault();

                    const activeElement = subMenuTrigger?.closest('.ddtmm-menu-item').querySelector('.ddtmm-sub-menu');
                    this.updateMobileWrapperHeight(activeElement);
                    activeElement.classList.add('active');

                    subMenuTrigger?.closest('.ddtmm-menu').classList.add('ddtmm-pushed');
                    subMenuTrigger?.closest('.ddtmm-site-nav').scroll({ top: 0, left: 0, behavior: 'smooth' });
                }
            });
        });

        const subMenuBackTriggers = this.drillDownMenu.querySelectorAll('.ddtmm-sub-menu-trigger-back');
        subMenuBackTriggers.forEach(subMenuBackTrigger => {
            subMenuBackTrigger.addEventListener('click', event => {
                if (breakpoints.down('xxl')) {
                    event.preventDefault();

                    subMenuBackTrigger.closest('.active').classList.remove('active');

                    const activeElement = subMenuBackTrigger.closest('.active');
                    const isCkSiteNav = activeElement.classList.contains('ddtmm-site-nav');
                    if (isCkSiteNav) {
                        this.updateMobileWrapperHeight(this.drillDownMenu.querySelector('.ddtmm-master-menu'));
                    } else {
                        this.updateMobileWrapperHeight(activeElement);
                    }

                    subMenuBackTrigger?.closest('.ddtmm-pushed').classList.remove('ddtmm-pushed');
                    subMenuBackTrigger?.closest('.ddtmm-site-nav').scroll({ top: 0, left: 0, behavior: 'smooth' });
                }
            });
        });

        this.onWindowSizeChangeStaticMenu();

        document.addEventListener('click', function (e) {
            const isSiteNavClose = e.target.classList.contains('js-site-nav-x');

            if (isSiteNavClose) {
                document.body.classList.remove('overflow-hidden--max-lg');
                const navOverlay = document?.querySelector('.ddtmm-site-nav-overlay');

                if (navOverlay) {
                    navOverlay.remove();
                }

                document?.querySelector('.ddtmm-site-nav')?.classList.remove('active');
                document?.querySelector('.ddtmm-site-nav-toggle')?.classList.remove('active');
            }
        }, false);

        const mainNavigationPoints = document.querySelectorAll('.ddtmm-master-menu > .ddtmm-menu-item');
        mainNavigationPoints.forEach((nav) => {
            nav.addEventListener('click', event => {
                const isTouchDevice = window.matchMedia('(any-pointer: coarse)').matches;
                const menuItem = (event.target).closest('.ddtmm-menu-item');

                if (breakpoints.up('xxl') && menuItem.classList.contains('ck-has-sub-menu') && isTouchDevice) {
                    event.preventDefault();

                    const menuTrigger = menuItem.querySelector('.ck-sub-menu-trigger');
                    const menu = menuItem.querySelector('.ddtmm-mega-menu');

                    mainNavigationPoints.forEach((mainNav) => {
                        if (mainNav !== menuItem) {
                            const mainNavTrigger = mainNav.querySelector('.ddtmm-sub-menu-trigger');
                            const mainNavMenu = mainNav.querySelector('.ddtmm-mega-menu');

                            mainNavTrigger.classList.remove('active');
                            if (mainNavMenu) {
                                mainNavMenu.classList.remove('open');
                                mainNavMenu.classList.add('closed');
                            }
                        }
                    });

                    if (menuTrigger?.classList.contains('active')) {
                        menuTrigger?.classList.remove('active');
                        menu?.classList.remove('open');
                        menu.classList.add('closed');
                    } else {
                        menuTrigger?.classList.add('active');
                        menu?.classList.add('open');
                        menu?.classList.remove('closed');
                    }
                }
            });
        });

        this.switchStyleWithSize();
    }

    onWindowSizeChangeStaticMenu() {
        this.switchStyleWithSize();
        window.addEventListener('resize', () => {
            this.switchStyleWithSize();
        });
        window.addEventListener('orientationchange', () => {
            this.switchStyleWithSize();
        });
    }

    switchStyleWithSize() {
        const columnBreakLinks = this.drillDownMenu.querySelectorAll('.js-column-break');

        if (breakpoints.down('xxl')) {
            if (this.size !== 'mobile') {
                this.size = 'mobile';

                columnBreakLinks.forEach((columnBreakLink) => {
                    const menuItem = columnBreakLink.closest('.ddtmm-menu-item');
                    if (menuItem === null) {
                        return;
                    }

                    menuItem.style.paddingBottom = '';
                });

                if (this.mobileWrapper.hasAttribute(DATA_MOBILE_HEIGHT)) {
                    this.mobileWrapper.style.height = this.mobileWrapper.getAttribute(DATA_MOBILE_HEIGHT);
                }
            }
        } else if (this.size !== 'desktop') {
            this.size = 'desktop';

            // close navigation if it was open on mobile
            this.getNav()?.classList.remove('active');
            this.getNavToggle()?.classList.remove('active');

            columnBreakLinks.forEach((columnBreakLink) => {
                const menuItem = columnBreakLink.closest('.ddtmm-menu-item');
                if (menuItem === null) {
                    return;
                }

                const menuWrapper = menuItem.closest('.ddtmm-mega-menu');
                if (menuWrapper === null) {
                    return;
                }

                const menuItemBuffer = 16;
                const menuItemPosition = menuItem.offsetTop;
                const menuItemHeight = menuItem.scrollHeight;
                const menuHeight = menuWrapper.scrollHeight;

                const menuItemPaddingBottom = menuHeight - (menuItemPosition + menuItemHeight) - menuItemBuffer;

                menuItem.style.paddingBottom = `${menuItemPaddingBottom}px`;
            });

            this.mobileWrapper.style.height = null;
        }
    }

    updateMobileWrapperHeight(activeElement) {
        const nav = activeElement.closest('.ddtmm-site-nav');
        const navBottomPadding = 50;
        const minHeight = nav.clientHeight - this.siteNavHeaderSelector.clientHeight - navBottomPadding;
        const activeElementHeight = activeElement.clientHeight + navBottomPadding;

        let wrapperHeight = `${minHeight}px`;
        if (activeElementHeight > minHeight) {
            wrapperHeight = `${activeElementHeight}px`;
        }

        this.mobileWrapper.style.height = wrapperHeight;
        this.mobileWrapper.setAttribute(DATA_MOBILE_HEIGHT, wrapperHeight);
    }

    getNav() {
        return this.drillDownMenu.querySelector('.ddtmm-site-nav');
    }

    getNavToggle() {
        return this.drillDownMenu.querySelector('.ddtmm-site-nav-toggle');
    }
}

function initDrillDownMenu() {
    const drillDownMenuElement = document.querySelector('.drilldown-to-mega-menu');
    if (drillDownMenuElement) {
        const drillDownMenu = new DrillDownMenu(drillDownMenuElement);
        drillDownMenu.mapEvents();
    }
}

module.exports = function () {
    initDrillDownMenu();
};

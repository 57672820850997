'use strict';

const throttle = require('lodash/throttle');
const breakpoints = require('../breakpoints');

module.exports = function () {
    if (!breakpoints.down('md')) {
        return;
    }

    const headerElement = document.querySelector('.js-header');
    const feedbackWrapper = document.querySelector('.feedback-element-wrapper');
    const accordionNavWrapper = document.querySelector('.js-accordion-nav-wrapper');

    if (!headerElement) {
        return;
    }

    const headerHeight = headerElement.clientHeight;
    const accordionNavWrapperHeight = accordionNavWrapper?.clientHeight || 0;
    let lastScrollY = window.scrollY;
    let isSticky = false;

    const updateFeedbackWrapperPosition = () => {
        if (!feedbackWrapper) {
            return;
        }

        const headerPosY = headerElement.getBoundingClientRect().top;
        const accordionNavWrapperPosY = accordionNavWrapper?.getBoundingClientRect().top || 0;

        let feedbackWrapperTop = 0;

        if (headerPosY === 0) {
            feedbackWrapperTop = headerHeight;
            if (accordionNavWrapperPosY === headerHeight) {
                feedbackWrapperTop += accordionNavWrapperHeight;
            }
        } else if (accordionNavWrapperPosY === 0) {
            feedbackWrapperTop = accordionNavWrapperHeight;
        }

        feedbackWrapper.style.top = `${feedbackWrapperTop}px`;
    };

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY < lastScrollY && !isSticky) {
            headerElement.classList.add('header--main-sticky');
            isSticky = true;
        } else if (currentScrollY >= lastScrollY && isSticky) {
            headerElement.classList.remove('header--main-sticky');
            isSticky = false;
        }

        lastScrollY = currentScrollY;
    };

    const observeMutations = (target, callback) => {
        new MutationObserver(callback).observe(target, {
            childList: true,
            subtree: true
        });
    };

    const observeIntersections = (target, callback) => {
        new IntersectionObserver(callback).observe(target);
    };

    window.addEventListener('scroll', throttle(() => {
        requestAnimationFrame(handleScroll);
    }, 100));

    if (feedbackWrapper) {
        observeMutations(feedbackWrapper, updateFeedbackWrapperPosition);
    }

    if (headerElement) {
        observeIntersections(headerElement, () => {
            updateFeedbackWrapperPosition();
        });
    }

    if (accordionNavWrapper) {
        observeIntersections(accordionNavWrapper, () => {
            updateFeedbackWrapperPosition();
        });
    }
};

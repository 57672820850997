'use strict';

const createPdf = require('./sharePdf');
const generateTextCartModal = require('./shareText');
const { showToastMessage } = require('./toast');
const materialIcon = require('../materialIcon');

/**
 * Extracts properties dynamically from the data attributes of an HTML element and returns an object.
 * This function assumes that data attributes are prefixed with 'data-' and keys are formatted as camelCase after 'data-'.
 * For example, 'data-share-msg-cart' becomes 'Cart' in the returned object.
 *
 * @param {HTMLElement} element - The HTML element from which to extract data attributes.
 * @returns {Object} An object containing key-value pairs where keys are camelCase properties derived from data attributes.
 */
function grabDynamicProperties(element) {
    const resources = {};
    const dataAttributes = element.dataset; // Extracts all data attributes from the element as an object.

    // Iterate over each attribute found in the dataset.
    Object.keys(dataAttributes).forEach((key) => {
        // Convert 'data-share-msg-cart' to 'Cart'
        let propName = key.replace(/Msg/g, '');

        // Assign the value to the new key in the resources object.
        resources[propName] = dataAttributes[key];
    });

    return resources;
}

/**
 * Safely grabs data from the DOM for PDF generation, with error handling and defaults.
 * @returns {Object} An object containing the basket model and resources needed for PDF creation.
 */
function grabCartData() {
    const cartItems = document.querySelectorAll('.product-info');
    const items = [];

    cartItems.forEach(item => {
        const itemName = item.querySelector('.js-line-item-name')?.innerText || 'Unknown Product';
        const itemSKU = item.querySelector('.js-line-item-id')?.innerText || 'Unknown SKU';
        const itemPrice = item.querySelector('.sales .js-unit-price')?.innerText || 0;
        const itemQuantity = parseInt(item.querySelector('.js-line-item-quantity')?.getAttribute('data-pre-select-qty') || 0, 10);
        const totalPrice = item.querySelector('.js-line-item-total-price-amount')?.innerText;
        const configuratorDetails = Array.from(item.querySelectorAll('.configurator-details li')).map(li => li.textContent.trim());

        items.push({
            productName: itemName,
            id: itemSKU,
            unitPrice: itemPrice,
            quantity: itemQuantity,
            totalPrice: totalPrice,
            configuratorProducts: configuratorDetails
        });
    });

    const orderDiscountElement = document.querySelector('.js-order-discount-total');
    const shippingDiscountElement = document.querySelector('.js-shipping-discount');

    const shippingCost = document.querySelector('.js-shipping-cost')?.innerText || 0;
    const orderDiscount = orderDiscountElement && !orderDiscountElement?.parentElement.classList.contains('hide-order-discount') ? document.querySelector('.js-order-discount-total')?.innerText : 0;
    const shippingDiscount = shippingDiscountElement && !shippingDiscountElement?.parentElement.classList.contains('hide-shipping-discount') ? document.querySelector('.js-shipping-discount')?.innerText : 0;
    const grandTotal = document.querySelector('.js-grand-total')?.innerText || 0;
    const taxRateText = document.querySelector('.js-tax-total-text')?.innerText || 'incl. VAT';
    const totalTax = document.querySelector('.js-tax-total')?.innerText || 0;

    return {
        items: items,
        totals: {
            totalShippingCost: shippingCost,
            orderLevelDiscountTotal: orderDiscount,
            shippingLevelDiscountTotal: shippingDiscount,
            grandTotal: grandTotal,
            taxRateText: taxRateText,
            totalTax: totalTax
        }
    };
}

/**
 * Attaches an event handler to the body for click events on a specified selector.
 * fetches data from a specified URL, and handles the response using a callback function.
 * It displays messages based on the response success or failure.
 * @param {string} selector - The selector for the elements that trigger the AJAX call on click.
 * @param {Function} callback - The callback function to process the AJAX response data.
 */
function handleShareClick(selector, callback) {
    $('body').on('click', selector, function (evt) {
        evt.preventDefault();
        const resources = grabDynamicProperties(evt.currentTarget);
        const basketModel = grabCartData();
        const result = callback({ basketModel, resources });

        if (result.success && result.showMsg) {
            showToastMessage({
                message: result.msg,
                alertType: 'alert-success',
                iconHTML: materialIcon.info
            });
        } else if (result.showMsg) {
            showToastMessage({ message: result.msg });
        }
    });
}

handleShareClick('.js-share-pdf-btn', createPdf);
handleShareClick('.js-share-text-btn', generateTextCartModal);

// require('bootstrap/js/src/util/backdrop.js');
// require('bootstrap/js/src/util/component-functions.js');
// require('bootstrap/js/src/util/config.js');
// require('bootstrap/js/src/util/focustrap.js');
// require('bootstrap/js/src/util/sanitizer.js');
// require('bootstrap/js/src/util/scrollbar.js');
// require('bootstrap/js/src/util/swipe.js');
// require('bootstrap/js/src/util/template-factory.js');
// require('bootstrap/js/src/util/bootstrap/js/src/alert.js');
// require('bootstrap/js/src/button.js');
require('bootstrap/js/src/carousel.js');
require('bootstrap/js/src/collapse.js');
require('bootstrap/js/src/dropdown.js');
require('bootstrap/js/src/modal.js');
require('bootstrap/js/src/alert.js');
require('bootstrap/js/src/scrollspy.js');
require('bootstrap/js/src/tab.js');
// require('bootstrap/js/src/tooltip.js');
require('bootstrap/js/src/popover.js');

'use strict';

const { jsPDF } = require('jspdf');
require('jspdf-autotable');

/**
 * Returns the current date and time formatted as DD.MM.YYYY HH:MM:SS.
 * @returns {string} The formatted date and time.
 */
function getFormattedDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
}

/**
 * Generates a PDF file for a shopping cart using provided data, including product details and totals.
 * @param {Object} data - The complete dataset used to generate the PDF.
 * @param {Object} data.basketModel - Contains all the items and pricing information.
 * @param {Array} data.basketModel.items - Array of items in the cart.
 * @param {Object} data.basketModel.totals - Contains totals like shipping costs and discounts.
 * @param {string} data.basketModel.totals.totalShippingCost - Total shipping cost for the cart.
 * @param {Object} data.basketModel.totals.orderLevelDiscountTotal - Total discounts applied at the order level.
 * @param {Object} data.basketModel.totals.shippingLevelDiscountTotal - Total discounts applied at the shipping level.
 * @param {string} data.basketModel.totals.grandTotal - Grand total price including all taxes and discounts.
 * @param {string} data.basketModel.totals.taxRateText - Description of the tax rate applied.
 * @param {string} data.basketModel.totals.totalTax - Total tax amount.
 * @param {Object} data.resources - Localized resource strings used in the PDF.
 * @returns {Object} Object indicating success or failure and containing an error message if an error occurred.
 */
function createPdf(data) {
    try {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'a4' });

        const currentDate = getFormattedDate();
        const cartData = data?.basketModel;
        const resources = data?.resources;

        // Document title and headers
        doc.setFontSize(16);
        doc.text(`${resources.shareCart}`, 14, 20);
        doc.setFontSize(12);
        doc.text(`${resources.shareCreationDate} ${currentDate}`, 14, 25);

        // Table headers
        const headers = [[resources.shareQuantity, resources.shareSku, resources.shareItems, resources.shareItemPrice, resources.shareItemTotal]];

        let tableData = [];

        // Process each main item in the cart data
        cartData.items.forEach(item => {
            // Add the main item row
            tableData.push([
                item.quantity,
                item.id,
                item.productName,
                item.unitPrice,
                item.totalPrice
            ]);

            // Check for and add configurator product details if available
            if (item.configuratorProducts && item.configuratorProducts.length > 0) {
                item.configuratorProducts.forEach(cp => {
                    tableData.push([
                        '', // Quantity is empty for configurator products
                        '', // SKU is empty for configurator products
                        ` - ${cp}`, // Prefix configurator product name with a dash to indicate nesting
                        '', // Price is empty for configurator products
                        '' // Total is empty for configurator products
                    ]);
                });
            }
        });

        // Add totals to the table data
        tableData.push(
            [{ content: `${resources.shareShippingCost}`, colSpan: 4, styles: { halign: 'left' } }, { content: cartData.totals.totalShippingCost }]
        );

        if (cartData.totals.orderLevelDiscountTotal) {
            tableData.push(
                [{ content: `${resources.shareOrderDiscount}`, colSpan: 4, styles: { halign: 'left' } }, { content: cartData.totals.orderLevelDiscountTotal }]
            );
        }

        if (cartData.totals.shippingLevelDiscountTotal) {
            tableData.push(
                [{ content: `${resources.shareShippingDiscount}`, colSpan: 4, styles: { halign: 'left' } }, { content: cartData.totals.shippingLevelDiscountTotal }]
            );
        }

        tableData.push(
            [{ content: `${resources.shareGrandTotal}`, colSpan: 4, styles: { halign: 'left', fontStyle: 'bold' } }, { content: cartData.totals.grandTotal, styles: { fontStyle: 'bold' } }],
            [{ content: `${cartData.totals.taxRateText}`, colSpan: 4, styles: { halign: 'left' } }, { content: cartData.totals.totalTax }]
        );

        // Generate table
        doc.autoTable({
            head: headers,
            body: tableData,
            headStyles: {
                fillColor: [0, 84, 147]
            },
            startY: 30,
            columnStyles: {
                0: { cellWidth: 20 },
                1: { cellWidth: 30 },
                2: { cellWidth: 80 },
                3: { cellWidth: 30, halign: 'right' },
                4: { cellWidth: 30, halign: 'right' }
            }
        });

        const filename = `Caseking_Cart_${getFormattedDate()}.pdf`;

        // Save the PDF
        doc.save(filename);

        return {
            success: true,
            error: false,
            msg: data?.resources.shareSuccess,
            showMsg: true
        };
    } catch (error) {
        return {
            success: false,
            error: true,
            msg: data?.resources.shareError || 'Error creating the PDF',
            showMsg: true
        };
    }
}

module.exports = createPdf;

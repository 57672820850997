
'use strict';

const Swiper = require('swiper/bundle').Swiper;
const initYotpoReview = require('../components/initYotpoReview');
function initSwiperSlider(slider) {
    const nextEl = slider.querySelector('.swiper-button-next');
    const prevEl = slider.querySelector('.swiper-button-prev');
    const sliderConfigDefault = {
        navigation: {
            nextEl,
            prevEl
        }
    };

    if (!slider) {
        return;
    }

    const sliderConfig = JSON.parse(slider.dataset.sliderConfig);
    const sliderSwiperElement = slider.querySelector('.swiper');
    // eslint-disable-next-line no-new
    new Swiper(sliderSwiperElement, { ...sliderConfigDefault, ...sliderConfig });
}

function intSwiperSliderWhenInView(slider) {
    const observer = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting) {
            initSwiperSlider(slider);
            observer.unobserve(slider);
        }
    }, {
        rootMargin: '50%'
    });
    observer.observe(slider);
}

module.exports = function () {
    const globalSwiperSelector = '.js-global-swiper-slider';
    document.querySelectorAll(`${globalSwiperSelector}`).forEach((slider)=>{
        intSwiperSliderWhenInView(slider);
    });

    $(document).on('einstein:initSlider', function (e) {
        const { einsteinSliderId } = e.detail;
        if (einsteinSliderId) {
            const einsteinSlider = document.querySelector(`#cq_recomm_slot-${einsteinSliderId} .js-global-swiper-slider`);
            if (einsteinSlider) {
                initSwiperSlider(einsteinSlider);
                const interval = setInterval(() => {
                    if (window.yotpo?.initialized) {
                        clearInterval(interval);
                        // eslint-disable-next-line no-undef
                        let api = new Yotpo.API(yotpo);
                        api.refreshWidgets();
                        initYotpoReview.refreshReviewCounts();
                    }
                }, 100);
            }
        }
    });
};

module.exports.initSwiperSlider = initSwiperSlider;

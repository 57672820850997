'use strict';

const scrollToElement = require('./scrollToElement');

const addScrollToListeners = function (element = null) {
    let triggers = null;

    if (element instanceof HTMLElement) {
        if (element.getAttribute('data-scroll-to')) {
            triggers = [element];
        } else {
            triggers = element.querySelectorAll('[data-scroll-to]');
        }
    } else {
        triggers = document.querySelectorAll('[data-scroll-to]');
    }

    triggers.forEach(trigger => {
        if (trigger.scrollToInit) {
            return;
        }

        trigger.scrollToInit = true;
        trigger.addEventListener('click', (event) => {
            event.preventDefault();
            const targetSelector = event.target.getAttribute('data-scroll-to');

            if (!targetSelector) {
                return;
            }

            const target = document.querySelector(targetSelector);

            if (target) {
                scrollToElement(target);
            }
        });
    });
};

module.exports = function (element = null) {
    addScrollToListeners(element);
};

'use strict';

module.exports = function (scrollToElement) {
    const scrollOffset = scrollToElement.getBoundingClientRect().top + window.scrollY;
    const headerHeight = document.querySelector('.js-header')?.getBoundingClientRect().height;

    if (scrollOffset && headerHeight) {
        window.scrollTo({
            top: scrollOffset - headerHeight
        });
    }
};
